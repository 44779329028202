import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Login } from '../_models/login';
import { DrpList } from '../_models/common-classes';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  endpoint: string = environment.urlAddress;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser = {};
  currentUserName = "";
  Isloadingspinner = false;
  ManagerDrpList: DrpList[];

  constructor(private http: HttpClient, private router: Router, private toastr: ToastrService) { }

  // Sign-in
  // signIn(user: User) {
  //   return this.http.post<any>(`${this.endpoint}/Login/Login`, user)
  //     .subscribe((res: any) => {
  //       localStorage.setItem('M2M_token', res.token)
  //       this.currentUser = res;
  //       localStorage.setItem('currentUserName', user.username)
  //       this.router.navigate(['home']);
  //       this.SuccessNoti("Welcome Back " + user.username);
  //     }),
  //     catchError(this.handleError);
  // }

  getToken() {
    var d = localStorage.getItem('M2M_token');
    if (d == null)
      return null;
    return JSON.parse(d).token;
  }

  getUsername() {
    return localStorage.getItem('currentUserName');
  }

  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('M2M_token');
    return (authToken !== null) ? true : false;
  }

  get GetManager(): number {
    let mid = localStorage.getItem('_Mn');
    return parseInt(mid == undefined ? '5' : mid);
  }

  get GetManagerName(): string {
    let mid = localStorage.getItem('_Mn');
    if (mid !== null) {
      var midi = parseInt(mid);
      if (this.ManagerDrpList != undefined)
        return this.ManagerDrpList ? this.ManagerDrpList.find(d => d.id == midi).name : '711';
    }
    return '711';
  }

  SetManager(mname: string) {
    localStorage.setItem('_Mn', mname);
  }

  doLogout() {
    let removeToken = localStorage.removeItem('M2M_token');
    localStorage.removeItem('currentUserName')
    if (removeToken == null) {
      this.router.navigate(['/lock']);
    }
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(msg);
  }

  signIn(user: Login) {
    return this.http.post<any>(environment.urlAddress + '/login/login', user);
  }


  public getById = (route: string) => {
    return this.http.get(this.createCompleteRoute(route, environment.urlAddress)).pipe(retry(1), catchError(this.handleError));
  }

  async getDataAsync(route: string) {
    return await this.http.get(this.createCompleteRoute(route, environment.urlAddress)).pipe(retry(1), catchError(this.handleError));
  }

  public getData = (route: string) => {
    return this.http.get(this.createCompleteRoute(route, environment.urlAddress)).pipe(retry(1), catchError(this.handleError));
  }

  public create = (route: string, body: any) => {
    return this.http.post(this.createCompleteRoute(route, environment.urlAddress), body, this.generateHeaders()).pipe(catchError(this.handleError));
  }

  public update = (route: string, body: any) => {
    return this.http.put(this.createCompleteRoute(route, environment.urlAddress), body, this.generateHeaders()).pipe(catchError(this.handleError));
  }

  public delete = (route: string) => {
    return this.http.delete(this.createCompleteRoute(route, environment.urlAddress)).pipe(catchError(this.handleError));
  }

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }


  SuccessNoti(msg: string) {

    this.toastr.success(msg, 'Done');


    // this._snackBar.open(msg, 'Close', {
    //   duration: 3000,
    //   panelClass: 'notif-success',
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition
    // });
  }

  ErrorNoti(msg: string) {
    this.toastr.error(msg, 'Error', {
      timeOut: 3000,
    });
    // this._snackBar.open(msg, 'Close', {
    //   duration: 3000,
    //   panelClass: 'notif-error',
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition
    // });
  }


  tinyAlert() {
    Swal.fire('Hey there!');
  }

  successNotification() {
    Swal.fire('Hi', 'We have been informed!', 'success')
  }

  alertConfirmation() {
    var d = false;
    Swal.fire({
      title: 'Are you sure?',
      text: 'This process is irreversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think'
    }).then((result) => {
      if (result.value) {
        d = true;
      }
      else {
        d = false;
      }
    })
    return d;
  }
}
